<template>
  <div>
    <v-row>
      <v-col>
        <v-card outlined>
          <v-card-title primary-title>
            Mudanças de Preços Recentes
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="products.data"
              :items-per-page="-1"
              ref="productTable"
              :mobile-breakpoint="0"
              disable-sort
              hide-default-footer
              dense
            >
              <template v-slot:[`item.price_cash`]="{ item }">
                {{ $format.decimal(item.product_variants[0].price_cash) }}
              </template>
              <template v-slot:[`item.price_forward`]="{ item }">
                {{ $format.decimal(item.product_variants[0].price_forward) }}
              </template>
              <template v-slot:[`item.cost_updated_at`]="{ item }">
                {{ $format.dateTimeBr(item.cost_updated_at) }}
              </template>
            </v-data-table>
          </v-card-text>

          <v-card-actions class="justify-center">
            <app-pagination @click="select($event)" :data="products" />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { text: "Código", value: "code", width: "5%", align: "center" },
        { text: "Produto", value: "name" },
        { text: "Preço AV", value: "price_cash", align: "center" },
        { text: "Preço PZ", value: "price_forward", align: "center" },
        {
          text: "Data",
          value: "cost_updated_at",
          align: "center",
          width: "20%",
        },
      ],
      products: {
        data: [],
      },
      searchParams: {
        cost_update_at_start: new Date(
          new Date().setDate(new Date().getDate() - 5)
        )
          .toISOString()
          .slice(0, 10),

        cost_update_at_end: new Date().toISOString().slice(0, 10),
        orderBy: "cost_updated_at",
        orderByDirection: "desc",
      },
    };
  },

  created() {
    this.select();
  },

  methods: {
    select(page) {
      this.$loading.start();
      this.searchParams.page = page;
      this.$http
        .index("product/product", this.searchParams)
        .then((response) => {
          this.products = response.products;
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
  },
};
</script>

<style>
</style>